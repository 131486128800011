<template>
    <div class="container-fluid" :style="{backgroundColor:bgColor}">
        <div class="line row py-2">
            <div class="col-11">
                <AppLink strclass="navbar-brand" name="global-feed"></AppLink>
                <img src="../assets/images/quote-img.png" class="favicon me-2" />
                <h3>
                    <span :style="{ color: titleColor }" @click="navigateToHome" class="text-effect">{{ title }}</span>
                    <span class="title-medium"> {{ updatedTitle }}</span>
                </h3>
            </div>
            <div class="col-1 d-flex justify-content-end">
                <!-- Toggle Icon starts -->
                <div v-if="isNewViewRolledOut" class="toggle-icon" v-tooltip='newView'>
                    <img :src="isToggled ? require('@/assets/icons/toggle-on.svg') : require('@/assets/icons/toggle-off.svg')"
                        @click="toggle" alt="Toggle Icon" class="toggle-icon-img" />
                </div>
                <!-- Toggle Icon ends -->
                <div v-if="!hideProfileIcon" class="col-1 d-flex justify-content-end">
                    <div class="dropdown">
                        <button class="nav-link" type="button" id="dropdownMenuButton" aria-expanded="false"></button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                            <li class="logout-list">
                                <a class="dropdown-item" href="#">
                                    <img src="../assets/icons/user.svg" alt="user logo" />
                                    {{ userName }}
                                </a>
                            </li>
                            <!-- <li class="logout-list"><a class="dropdown-item" href="#">Another action</a></li> -->
                            <li class="logout-list">
                                <hr class="dropdown-divider" />
                            </li>
                            <li class="logout-list" v-if="isUserSuperAdmin">
                                <button @click="manageRoles" class="dropdown-item text-danger" type="button">
                                    <img src="../assets/icons/users.svg" alt="Logout logo"
                                        class="logout-icon manage-roles-icon" />
                                    Accounts & access
                                </button>
                            </li>
                            <li class="logout-list">
                                <button @click="logout" class="dropdown-item text-danger" type="button">
                                    <img src="../assets/icons/logout.svg" alt="Logout logo" class="logout-icon" />
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getCookie,
    deleteCookie
} from "@/store/cookie";
import {
    user,
    pageViews,
    role
} from "@/utils/util";
import {
  makeAuthenticationRequest,
  getTitle
} from '@/services/apiServices';
import {
    useAuthStore
} from "../store/auth.js";
import { getSessionItem } from "@/store/session.js";
export default {
    name: pageViews.HEADERVIEW,
    data() {
        return {
            userName: getCookie(user.name),
            updatedTitle: "",
            isToggled: false,
            newView: "Try New View",
        };
    },
    computed: {
        hideProfileIcon() {
            return this.$route.meta.hideProfileIcon;
        },
        titleColor() {
            return this.$route.meta.titleColor;
        },
        bgColor() {
            return this.$route.meta.bgColor;
        },
        title() {
            return this.$route.meta.title;
        },
        isUserSuperAdmin() {
            return getCookie(user.role) === role.superadmin;
        },
        isNewViewRolledOut() {
            return this.$acclConfigs['rollout-new-view'];
        },
    },
    watch: {
      '$route.params.projectParamId': function(projectId) {
        this.updateTitle(projectId, this.$route.name);
      }
    },
    updated() {
        this.userName = getCookie(user.name);
    },
    methods: {
        async logout() {
            const body = {
                userId: getCookie(user.id),
                authSource: getCookie(user.source)
            }
            //const response = await axios.post(process.env.VUE_APP_BASE_AUTH_URL + "/logout", body)
            try {
                const response = await makeAuthenticationRequest(this.$acclConfigs, "/logout", body)
                if (response.status == 200) {
                    deleteCookie(user.id, user.name, user.source);
                    this.clearJWTToken();
                    this.$router.push({
                        name: pageViews.LOGINVIEW,
                    });
                }
            } catch (error) {
              console.log("Error message: Logout API Failed: ", error.message);
            }

        },
        toggle() {
            this.isToggled = !this.isToggled;
            if(this.isToggled){
                this.newView = "Switch to Classic View";
                //window.location.href = 'https://google.com';
                this.$router.push({name: pageViews.VIEW_404});
            }else{
                this.newView = "Try New View"
                this.$router.push({name: pageViews.ALLINONE_ACC_VIEW});
            }
        },
        navigateToHome() {
            if(this.isToggled){
                this.isToggled = !this.isToggled;
                this.newView = "Try New View"
            }
            console.log(this.isToggled);
            this.$router.push({
                name: pageViews.ALLINONE_ACC_VIEW,
            });
        },
        async updateTitle(projectId, routeName) {
            if (![pageViews.ALLINONE_ACC_VIEW, pageViews.TRASHVIEW, pageViews.LOGINVIEW, pageViews.VIEW_404, pageViews.ERROR_VIEW, pageViews.MANAGE_USERS_VIEW].includes(routeName)) {
                try {
                    const response = await getTitle(this.$acclConfigs, projectId, 'project');
                    if (response.status == 200) {
                        let prefix = "-";
                        this.updatedTitle = prefix + ' ' + response.data.title;
                    }
                } catch (error) {
                    console.error('Error making api call - getTitle()', error);
                }
            } else {
                this.updatedTitle = "";
            }
        },
        async manageRoles(){
            this.$router.push({
                name: pageViews.MANAGE_USERS_VIEW,
            });
        },
    },
    setup() {
        const authStore = useAuthStore();

        function clearJWTToken() {
            authStore.clearToken();
        }
        return {
            clearJWTToken,
        };
    },
};
</script>

<style scoped>
.toggle-icon {
  cursor: pointer;
  margin-right: 30%;
}

.toggle-icon-img {
  transition: color 0.3s;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    /* top: 100%; */
    right: 0;
    min-width: 10rem;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.container-fluid h2 {
    font-family: system-ui;
}

.h3 {
    font-size: medium;
}

.text-effect {
    display: inline-block;
    transition: all 0.1s ease;
}

.text-effect:hover {
    text-shadow: 0 0 0.1px #da6a26, 0 0 0.1px #da6a26, 0 0 0 #da6a26;
    cursor: pointer;
    color: #da6a26;
}

.logout-list {
    display: block;
}

.favicon {
    /* height: 4vh; */
    width: 1.3vw;
    float: left;
}

/* .col-6 {
  margin: top 10px;
} */

.nav-link {
    background: url("../assets/icons/profile.svg") no-repeat center;
    background-size: cover;
    font-size: 12px;
    margin-top: 3px;
    height: 1.5vh;
    width: 1.5vw;
    border: none;
    padding: 50%;
    padding-right: 20%;
}

.navbar-nav {
    margin-left: auto;
    /* This will align the nav items to the right */
}

nav {
    /* background-color: #cd7838; */
    padding: 1em;
    color: black;
    /* text-align: left; */
    position: fixed;
    border-radius: 0.25rem;
    position: relative;
    padding: 0.5rem 1rem;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline;
    margin-right: 1em;
}

a {
    color: #050505;
    text-decoration: none;
    font-weight: bold;
}

.title-medium {
  font-size: x-large;
  margin-left: 0.7rem;
}

.manage-roles-icon {
    margin-left: -0.2rem;
}

</style>
