import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
//import './utils/mock'
import ToastService from 'primevue/toastservice';
import CodeDiff from 'v-code-diff'

import 'bootstrap/dist/css/bootstrap.min.css';
import * as bootstrap from "bootstrap";
import { createPinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import AWS from 'aws-sdk';


AWS.config.update({
    accessKeyId: window.acclConfigs.AWS_PARAM_STORE_ACCESS_KEY,
    secretAccessKey: window.acclConfigs.AWS_PARAM_STORE_ACCESS_SECRET,
    region: window.acclConfigs.AWS_PARAM_STORE_REGION
});
const ssm = new AWS.SSM();
async function loadConfigs() {
    const environment = window.acclConfigs.VUE_APP_ENVIRONMENT;
    const params = {
        Names: [
            `/accl-suite-ui/${environment}/common-api-url`,
            `/accl-suite-ui/${environment}/xml-transformation-api-url`,
            `/accl-suite-ui/${environment}/authentication-api-url`,
            `/accl-suite-ui/${environment}/file-upload-s3-bucket-name`,
            `/accl-suite-ui/${environment}/file-upload-s3-bucket-region`,
            `/accl-suite-ui/${environment}/file-upload-s3-bucket-accessKey`,
            `/accl-suite-ui/${environment}/file-upload-s3-bucket-accessSecret`,
            `/accl-suite-ui/${environment}/rollout-new-view`,
        ],
        WithDecryption: true
    };
    try {
        const response = await ssm.getParameters(params).promise();
        const config = response.Parameters.reduce((acc, param) => {
            const key = param.Name.split('/').pop();
            acc[key] = param.Value;
            return acc;
        }, {});
        return config;
    } catch (error) {
        console.error("Error loading configurations required for the app to work", error);
        return {};
    }
}

loadConfigs().then((config) => {
    const app = createApp(App);

    /**Initialize Pinia for state management */
    const pinia = createPinia();
    pinia.use(createPersistedState());

    app.use(CodeDiff)
    app.use(router);
    app.use(ToastService);
    app.use(pinia);



    app.config.globalProperties.$statuses = [
        {
            name: "ok",
            label: "Success"
        },
        {
            name: "in-prog",
            label: "In Progress"
        },
        {
            name: "fail",
            label: "Failed"
        },
        {
            name: "new",
            label: "New"
        }
    ];
    app.config.globalProperties.$acclConfigs = config;
    app.directive('tooltip', {
        mounted(el, binding) {
            el._tooltipInstance = new bootstrap.Tooltip(el, {
                placement: 'top',
                title: binding.value ? binding.value : '',
                customClass: 'custom-tooltip'
            });
            // Listen for the custom event to hide the tooltip
            window.addEventListener('hide-tooltips', () => {
                if (el._tooltipInstance) {
                    el._tooltipInstance.hide();
                }
            });
        },
        updated(el, binding) {
            if (el._tooltipInstance) {
                el._tooltipInstance.setContent({ '.tooltip-inner': binding.value ? binding.value : '' });
            } else {
                el._tooltipInstance = new bootstrap.Tooltip(el, {
                    placement: 'top',
                    title: binding.value ? binding.value : '',
                    trigger: 'hover focus',
                    customClass: 'custom-tooltip'
                });
            }
        },
        unmounted(el) {
            if (el._tooltipInstance) {
                el._tooltipInstance.dispose();
                delete el._tooltipInstance;
            }

            // Remove the event listener
            window.removeEventListener('hide-tooltips', () => {
                if (el._tooltipInstance) {
                    el._tooltipInstance.hide();
                }
            });
        }
    });
    app.mount('#app');
    //console.log('finalyyyyy======>', this.$acclConfigs);
});




//createApp(App).use(router).mount('#app')
