import { defineStore } from 'pinia';

export const useAuthStore = 
    defineStore('auth', {
        state: () => ({
            id_token: null,
        }),
        actions: {
            setToken(token) {
                this.id_token = token;
            },
            clearToken() {
                this.id_token = null;
            },
        },
        persist: true
    })