import axios from 'axios';
import apiClient from './apiClient.js';

export async function makeOpearationRequest(acclConfigs, endPoint, payload, config = {}) {
  try {
    const response = await apiClient.post(
      acclConfigs['common-api-url'] + endPoint,
      payload,
      config
    );
    return response; // Simply return the response
  } catch (error) {
    // Handle error gracefully
    console.error("Error making request:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
}

export async function makeXMLTransformRequest(acclConfigs, endPoint, payload) {
      try {
        const response = await apiClient.post(
          acclConfigs['xml-transformation-api-url'] + endPoint,
          payload
        );
        return response; // Simply return the response
      } catch (error) {
        // Handle error gracefully
        console.error("Error making request:", error);
        throw error; // Re-throw the error to be handled by the caller
      }
}

export async function makeAuthenticationRequest(acclConfigs, endPoint, payload) {
      try {
        const response = await apiClient.post(
          acclConfigs['authentication-api-url'] + endPoint,
          payload
        );
        return response; // Simply return the response
      } catch (error) {
        // Handle error gracefully
        console.error("Error making request:", error);
        throw error; // Re-throw the error to be handled by the caller
      }
}

export async function getTitle(acclConfigs, reqId, type) {
  let getTitleReqBody = {
    type: type,
    requestId: reqId
  }
  try {
    const response = await makeOpearationRequest(acclConfigs, "/getTitle", getTitleReqBody);
    /* const response = {
      status: 200,
      data: {
        title: "ProjectFileName"
      }
    } */
    return response;
  } catch (error) {
    throw error;
  }
}