<template>
  <div class="popup-overlay" @click="closePopup">
    <div class="popup-content" @click.stop>
      <div class="sticky-header">
        <div>
          <strong>{{ title }}</strong>
          <button @click="closePopup" class="close-button">&times;</button>
        </div>
      </div> 
      <div class="tab-buttons sticky-header">
        <button v-for="(data, index) in viewContent" :key="index" @click="selectedTab = index" :class="{ active: selectedTab === index }"> {{ data.title }}</button>
      </div>
      
      <div class="tab-content">
        <div v-for="(data, index) in viewContent" :key="index">
          <div v-if="index === 0  && selectedTab ===0">
            <TreeItem :data="data.content" />
            <!-- <div v-if="data.title==='Lineage'"> -->
            <!-- <pre class="nowrap" v-if="selectedTab === index"><code :class="`language-${data.format}`">
              <TreeItem :data="data.content" />
            </code></pre> -->
          </div>
          <div v-if="index!=0">
            <!-- <div v-else-if="index!=0"> -->
            <pre class="nowrap"
              v-if="selectedTab === index && data.title!='Lineage'"><code :class="`language-${data.format}`">{{ data.content }}</code></pre>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as yaml from 'js-yaml';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import { registerHljsLineNumbers, injectHljsLineNumbersCss } from '../utils/highlight-line-numbers-plugin.js';
import TreeItem from './TreeItem.vue';
export default {
  props: {
    viewContent: {
      type: Array,
      required: true,
    },
    setShowPop: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: true,
    }
  },
  computed: {
  },
  components: {
    TreeItem,
  },

  methods: {
    closePopup() {
      this.setShowPop(false);
    },
    highlightCode() {
      // Highlight the specific code block in the selected tab
      this.$nextTick(() => {
        hljs.highlightAll();
        hljs.initLineNumbersOnLoad();
      });
    }
  },
  data() {
    return {
      selectedTab: 0,
      format: "yaml",

    }
  },
  watch: {
    // Re-highlight code when the selected tab changes
    selectedTab() {
      this.$nextTick(() => {
        this.highlightCode();
      });
    }
  },
  mounted() {
    window.dispatchEvent(new Event('hide-tooltips'));
    registerHljsLineNumbers(hljs);
    injectHljsLineNumbersCss();
    this.highlightCode();
  },
};
</script>

<style scoped>
.strong {
  font-size: larger;
}

.nowrap {
  white-space: pre !important;
  overflow: unset !important;
}

.tab-buttons {
  display: flex;
  /* margin-bottom: 10px; */
}
.tab-content {
  overflow: auto !important;
  max-height: 80vh;
  height: 80%;
  background: #F3F3F3;
}

.tab-buttons button {
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: #ddd;
  border-radius: 15px;
  font-size: smaller;
  font-weight: bold;
  margin-right: 3px;
}

.tab-buttons button.active {
  background-color: #ddd;
  border-bottom: 3px solid #da6a26;
  border-radius: 15px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  background: rgba(0,0,0,0.5);
  z-index: 1;
}

.popup-content {
  background: white;
  padding: 20px;
  padding-top: 10px;
  border-radius: 8px;
  width: 80%; 
  max-width: 50rem; 
  height: 80%; 
  max-height: 80vh;
  position: relative;
  text-align: center; 
  border-style: ridge;
}

pre {
  margin: 0;
  margin-right: 0.7rem;
  padding: 1rem;
  color: #333;
  background: #f5f5f5;
  font-family: monospace;
  white-space: pre-wrap; 
  text-align: left;
}

pre code.hljs {
  overflow-x: unset !important;
}

button.close-button {
  position: absolute;
  top: 0.001rem;
  right: 0.1rem;
  background: transparent;
  color: red;
  border: none;
  font-size: 1.3rem; 
  font-weight: bold;
  cursor: pointer;
  padding: 0;
}

button.close-button:hover {
  color: black;
}

.sticky-header {
  position: -webkit-sticky; 
  position: sticky;
  top: -1.5vh;
  background-color: white; 
  padding: 8px;
  z-index: 1000; 
}

</style>
<style>

/* .hljs-ln-line {
  padding: 0; 
  margin: 0;
} */
.hljs-ln-numbers {
  text-align: center;
  color: rgb(31 35 40 / 38%);
  border-right: 1px solid #ddd;
  padding-right: 10px !important;
  background: #f5f5f5;
  vertical-align: top; /* Align to top if necessary */
  white-space: nowrap; /* Prevent line wrapping */
}
 
/* for block of code */
.hljs-ln-code {
    line-height: 0%;
    position: relative;
    left: -4%;
 
}
.hljs-ln-n {
  /* Styling for the line numbers */
  display: block;
  line-height: 1.5; /* Adjust as needed */
  padding: 0; /* Remove extra padding */
}
.hljs-attr {
  /* Styling for attributes */
  display: inline; /* Ensure no extra space is added */
  padding: 0; /* Remove extra padding */
}


</style>
