import { createRouter, createWebHistory } from 'vue-router'; // Import necessary functions from vue-router


// import Converter from './views/ConverterView.vue';

import ConverterView from '../views/ConverterView.vue';
import CallbackView from '../views/CallbackView.vue';
import FileDifference from '../components/FileDIfference.vue';

import TransfomerView from '../views/TransformerView.vue'
import TrashView from '../views/TrashView.vue';
import LoginView from '../views/LoginView.vue';
import IndependentAcceleratorsView from '../views/IndependentAcceleratorsView.vue';
import AllInOneAcceleratorsView from '../views/AllInOneAcceleratorsView.vue';
import SchemaConverterView from '../views/SchemaConverterView.vue';
import KafkaConfigGeneratorView from '../views/KafkaConfigGeneratorView.vue';
import ManageUsersView from '../views/ManageUsersView.vue';
import UnderConstructionView from '../components/tbd.vue';
import ErrorPageView from '../views/ErrorPageView.vue';

import { getCookie } from '../store/cookie';
import { user } from '../utils/util';
import { useAuthStore } from "../store/auth";

const headerTitle = process.env.VUE_APP_TITLE;
const routes = [
  { 
    path: '/login', 
    name: 'LoginView', 
    component: LoginView, 
    meta: { hideProfileIcon: true, titleColor: 'White', bgColor: '#03122F', title: headerTitle } 
  },
  { 
    path: '/callback', 
    name: 'CallbackView', 
    component: CallbackView 
  },
  { 
    path: '/:projectParamId?',
    name: 'AllInOneAcceleratorsView', 
    component: AllInOneAcceleratorsView, 
    meta: { requiresAuth: true, title: headerTitle } ,
    props: true,
  },
  { 
    path: '/:projectParamId/schemaconverter/:xsdParamId?', 
    name: 'SchemaConverterView', 
    component: SchemaConverterView ,
    meta: {title: headerTitle,requiresAuth: true,},
    props: true,
  },
  { 
    path: '/:projectParamId/kafkaconfiggenerator/:jmsParamId?', 
    name: 'KafkaConfigGeneratorView', 
    component: KafkaConfigGeneratorView,
    meta: {title: headerTitle, requiresAuth: true,} ,
    props: true,
  },
  { 
    path: '/:projectParamId/xsltconverter/:xslParamId?', 
    name: 'ConverterView', 
    component: ConverterView, 
    meta: { requiresAuth: true, title: headerTitle } ,
    props: true,
  },
  { 
    path: '/independent', 
    name: 'IndependentAcceleratorsView', 
    component: IndependentAcceleratorsView, 
    meta: { requiresAuth: true, title: headerTitle },
    redirect: '/xsltconverter',
  },
  {
    path: '/:projectParamId/xsltconverter/:xslParamId?/compare',
    name: 'FileDifferenceNew',
    component: FileDifference,
    props: true,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  { 
    path: '/:projectParamId/xsltconverter/:xslParamId/xml-transformer/:xmlParamId?', 
    name: 'XmlTransformView', 
    component: TransfomerView, 
    meta: { requiresAuth: true, title: headerTitle },
    props: true, 
  },
  {
    path: '/:projectParamId/xsltconverter/:xslParamId/xml-transformer/:xmlParamId/compare',
    name: 'XMLFileDifferenceNew',
    component: FileDifference,
    props: true,
    meta: { requiresAuth: true, title: headerTitle },
    props: true,
  },
  { 
    path: '/trash-view', 
    name: 'TrashView', 
    component: TrashView, 
    meta: { requiresAuth: true, title: headerTitle } 
  },
  {
    path: '/manageusers',
    name: 'ManageUsersView',
    component: ManageUsersView,
    meta: { requiresAuth: true, title: headerTitle }
  },
  {
    path: '/underconstruction',
    name: 'UnderConstructionView',
    component: UnderConstructionView,
    meta: { title: headerTitle }
  },
  { 
    path: '/:preMatch(.*)*', 
    name: 'ErrorPageView', 
    component: ErrorPageView, 
    meta: { title: headerTitle } 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const authStore = useAuthStore();
    const id_token = authStore.id_token;
    const userId = getCookie(user.id);
    if (id_token && userId) {
      next();
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});
export default router;
