<template>
<ul class="pagination">
    <li class="pagination-item">
        <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
            <<
        </button>
    </li>

    <li class="pagination-item">
        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
            <
        </button>
    </li>

    <!-- Visible Buttons Start -->

    <li v-for="page in pages" :key="page.name" class="pagination-item">
        <button type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ active: isPageActive(page.name) }">
            {{ page.name }}
        </button>
    </li>

    <!-- Visible Buttons End -->

    <li class="pagination-item">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
            >
        </button>
    </li>

    <li class="pagination-item">
        <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
            >>
        </button>
    </li>
</ul>
</template>

<script>
export default {
    name: "Pagination",
    props: {
        maxVisibleButtons: {
            type: Number,
            required: false,
            default: 3
        },
        totalPages: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    computed: {
        startPage() {
            // When on the first page
            if (this.currentPage === 1) {
                return 1;
            }

            // When on the last page
            if (this.currentPage === this.totalPages) {
                let startPageNo = this.totalPages - this.maxVisibleButtons + 1;
                return startPageNo > 0 ? startPageNo : 1;
                //return this.totalPages - this.maxVisibleButtons + 1;
            }

            // When inbetween
            return this.currentPage - 1;
        },
        pages() {
            const range = [];
            for (
                let i = this.startPage; i <= Math.min(this.startPage + (this.maxVisibleButtons - 1), this.totalPages); i++
            ) {
                range.push({
                    name: i,
                    isDisabled: i === this.currentPage
                });
            }

            return range;
        },
        isInFirstPage() {
            return this.currentPage === 1;
        },
        isInLastPage() {
            return this.currentPage === this.totalPages;
        },
    },
    methods: {
        onClickFirstPage() {
            this.$emit('pagechanged', 1);
        },
        onClickPreviousPage() {
            this.$emit('pagechanged', this.currentPage - 1);
        },
        onClickPage(page) {
            this.$emit('pagechanged', page);
        },
        onClickNextPage() {
            this.$emit('pagechanged', this.currentPage + 1);
        },
        onClickLastPage() {
            this.$emit('pagechanged', this.totalPages);
        },
        isPageActive(page) {
            return this.currentPage === page;
        }
    }
};
</script>

<style scoped>
/* .pagination {
    list-style-type: none;
}

.pagination-item {
    display: inline-block;
}

.active {
    background-color: #cd7838;
    color: #ffffff;
    font-weight: bolder;
} */
 /* General styling for the pagination bar */
.pagination {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Styling for pagination items */
.pagination-item {
  margin: 0 0;
}

/* Styling for pagination buttons */
.pagination-item button {
  background-color: white;
  color: #cd7838;
  border: none;
  border-radius: 10px;
  padding: 0px 10px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  outline: none;
}

/* Styling for disabled buttons */
.pagination-item button:disabled {
  background-color: white;
  cursor: not-allowed;
  color: lightgray;
}

/* Styling for the active page */
.pagination-item button.active {
  text-decoration: underline;
  font-weight: bold;
  color: #cd7838;
}

/* Hover effect */
.pagination-item button:hover:not(:disabled) {
  transform: scale(1.1);
}

/* Focus effect */
/* .pagination-item button:focus {
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
} */
</style>
