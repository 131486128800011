import axios from "axios";
import { useAuthStore } from "../store/auth";
import router from '../router/router.js';
import { pageViews } from "@/utils/util";

const apiClient = axios.create({
   /*  baseURL: , */ //When there is a commmon baseURL, we can add it here
});

apiClient.interceptors.request.use(
    (config) => {
        const authStore = useAuthStore();
        const id_token = authStore.id_token;
        if(id_token) {
            config.headers['Authorization'] = `Bearer ${id_token}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => response, async (error) => {
        const original_request = error.config;
        if(error.response.status === 401){
            //refresh token api to be implemented.
            router.push({
                name: pageViews.LOGINVIEW
            });

        }else if(error.response.status === 403) {
            router.push({
                name: pageViews.ERROR_VIEW
            });
        }
        return Promise.reject(error);
    }
);
export default apiClient;