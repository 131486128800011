import { defineStore } from 'pinia';

export const useUploadStore = defineStore(
    "upload", {
    state: () => ({
        uploadComplete: false,
    }),
    actions: {
        setUploadComplete(status) {
            this.uploadComplete = status;
        },
    },
});