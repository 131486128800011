<template>
    <!-- <IndependentAcceleratorsView /> -->
    <h2>
        <img src="../assets/images/quote-img.png" class="favicon me-2">You are not entitled to view this page..
    </h2>
</template>

<script>
import { pageViews } from "../utils/util";
import IndependentAcceleratorsView from "../views/IndependentAcceleratorsView.vue";

export default {
    name: pageViews.ERROR_VIEW,
    components: {
        /* IndependentAcceleratorsView, */
    }
     
}

</script>