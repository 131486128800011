export const mock_response = {
    USER_ROLES_SEARCH_API: {
        status: 200,
        data: {
            "userId": "sasidaren",
            "transactions": [
                {
                    "userId": "u100",
                    "userName": "DS",
                    "email": "ds@psyncop[ate.com",
                    "idp": "Azure",
                    "role": "user",
                },
                {
                    "userId": "u101",
                    "userName": "DS",
                    "email": "ds@psyncop[ate.com",
                    "idp": "Azure",
                    "role": "user",
                },
                {
                    "userId": "u102",
                    "userName": "DS",
                    "email": "ds@psyncop[ate.com",
                    "idp": "Azure",
                    "role": "user",
                },
            ],
            "totalRecords": 3
        }
    },
    SCHMEA_SEARCH_API: {
        status: 200,
        data: {
            "userId": "sasidaren",
            "transactions": [
                {
                    "xsdId": "xsl26346",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:41.338314",
                    "lastModifiedDate": "2024-08-28T12:38:41.558129",
                    "remarks": "File processed successfully",
                    "userId": "Sasidaren",
                    "isDeleted": false,
                    "xsdFileName": "InvokeCatalogAdvancePay_SetReceivableExtPrevBal.xsl",
                    "projectId": "prj10027"
                },
                {
                    "xsdId": "xsl26346",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:41.338314",
                    "lastModifiedDate": "2024-08-28T12:38:41.558129",
                    "remarks": "File processed successfully",
                    "userId": "Sasidaren",
                    "isDeleted": false,
                    "xsdFileName": "InvokeCatalogAdvancePay_SetReceivableExtPrevBal.xsl",
                    "projectId": "prj10027"
                },
                {
                    "xsdId": "xsl26346",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:41.338314",
                    "lastModifiedDate": "2024-08-28T12:38:41.558129",
                    "remarks": "File processed successfully",
                    "userId": "Sasidaren",
                    "isDeleted": false,
                    "xsdFileName": "InvokeCatalogAdvancePay_SetReceivableExtPrevBal.xsl",
                    "projectId": "prj10027"
                },
            ],
            "totalRecords": 35
        }
    },
    XSL_SEARCH_API: {
        status: 200,
        data: {
            "userId": "Rafik",
            "transactions": [
                {
                    "xslId": "xsl26346",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:41.338314",
                    "lastModifiedDate": "2024-08-28T12:38:41.558129",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogAdvancePay_SetReceivableExtPrevBal.xsl",
                    "tibcoCount": 0,
                    "convertedCount": 0,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "",
                    "dependentFunctionList": "",
                    "projectId": "prj10027",
                    "lineCount": 218,
                    "procId": "prc11161"
                },
                {
                    "xslId": "xsl26345",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:35.819053",
                    "lastModifiedDate": "2024-08-28T12:38:41.078872",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogAdvancePay_InitialTransformToJCPPOS.xsl",
                    "tibcoCount": 51,
                    "convertedCount": 51,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "pad,string_round_fraction,pad_front,round_fraction,compare_date",
                    "dependentFunctionList": "padding",
                    "projectId": "prj10027",
                    "lineCount": 3551,
                    "procId": "prc11161"
                },
                {
                    "xslId": "xsl26344",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:35.138236",
                    "lastModifiedDate": "2024-08-28T12:38:35.358005",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogAdvancePay_return_bindings.xsl",
                    "tibcoCount": 0,
                    "convertedCount": 0,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "",
                    "dependentFunctionList": "",
                    "projectId": "prj10027",
                    "lineCount": 27,
                    "procId": "prc11161"
                },
                {
                    "xslId": "xsl26343",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:28.658874",
                    "lastModifiedDate": "2024-08-28T12:38:34.021374",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeAbortSale_TransformToJCPPOSXml.xsl",
                    "tibcoCount": 52,
                    "convertedCount": 52,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "pad,string_round_fraction,pad_front,compare_date",
                    "dependentFunctionList": "padding",
                    "projectId": "prj10027",
                    "lineCount": 3039,
                    "procId": "prc11159"
                },
                {
                    "xslId": "xsl26342",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:28.03828",
                    "lastModifiedDate": "2024-08-28T12:38:28.237975",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeAbortSale_return_bindings.xsl",
                    "tibcoCount": 0,
                    "convertedCount": 0,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "",
                    "dependentFunctionList": "",
                    "projectId": "prj10027",
                    "lineCount": 29,
                    "procId": "prc11159"
                },
                {
                    "xslId": "xsl26341",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:20.819233",
                    "lastModifiedDate": "2024-08-28T12:38:26.663168",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogPOSReturn_TransformToJCPPOS.xsl",
                    "tibcoCount": 58,
                    "convertedCount": 58,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "pad,string_round_fraction,pad_front,compare_date",
                    "dependentFunctionList": "padding",
                    "projectId": "prj10027",
                    "lineCount": 3532,
                    "procId": "prc11158"
                },
                {
                    "xslId": "xsl26340",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:20.160664",
                    "lastModifiedDate": "2024-08-28T12:38:20.358008",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogPOSReturn_return_bindings.xsl",
                    "tibcoCount": 0,
                    "convertedCount": 0,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "",
                    "dependentFunctionList": "",
                    "projectId": "prj10027",
                    "lineCount": 29,
                    "procId": "prc11158"
                },
                {
                    "xslId": "xsl26339",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:17.158372",
                    "lastModifiedDate": "2024-08-28T12:38:19.262096",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogPickup_TransformToJCPPOS.xsl",
                    "tibcoCount": 20,
                    "convertedCount": 20,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "pad,compare_date",
                    "dependentFunctionList": "padding",
                    "projectId": "prj10027",
                    "lineCount": 966,
                    "procId": "prc11157"
                },
                {
                    "xslId": "xsl26338",
                    "status": "ok",
                    "requestDate": "2024-08-28T12:38:16.681157",
                    "lastModifiedDate": "2024-08-28T12:38:16.87798",
                    "remarks": "File processed successfully",
                    "userId": "Rafik",
                    "isDeleted": false,
                    "xslFileName": "InvokeCatalogPickup_return_bindings.xsl",
                    "tibcoCount": 0,
                    "convertedCount": 0,
                    "nonConvertedCount": 0,
                    "unAvailableFunctionList": "",
                    "customFunctionList": "",
                    "dependentFunctionList": "",
                    "projectId": "prj10027",
                    "lineCount": 29,
                    "procId": "prc11157"
                }
            ],
            "totalRecords": 900
        }
    },
    XML_SEARCH_API: {
        status: 200,
        data: {
            "userId": "sasidaren",
            "transactions": [
            ]
        }
    },
    KAFKA_SEARCH_API: {
        status: 200,
        data: {
            userId: "Bhaswanth",
            "transactions": [{
                "jmsId": 1,
                "userId": "br",
                "jmsFileName": "abc.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "fail",
                "noOfTopics": 1,
                "noOfQueues": 1,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Request not accepted"
              },{
                "jmsId": 2,
                "userId": "br",
                "jmsFileName": "xyz.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "ok",
                "noOfTopics": 13,
                "noOfQueues": 13,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Succesfully processed"
              },{
                "jmsId": 1,
                "userId": "br",
                "jmsFileName": "abc.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "fail",
                "noOfTopics": 1,
                "noOfQueues": 1,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Request not accepted"
              },{
                "jmsId": 2,
                "userId": "br",
                "jmsFileName": "xyz.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "ok",
                "noOfTopics": 13,
                "noOfQueues": 13,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Succesfully processed"
              },{
                "jmsId": 1,
                "userId": "br",
                "jmsFileName": "abc.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "fail",
                "noOfTopics": 1,
                "noOfQueues": 1,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Request not accepted"
              },{
                "jmsId": 2,
                "userId": "br",
                "jmsFileName": "xyz.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "ok",
                "noOfTopics": 13,
                "noOfQueues": 13,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Succesfully processed"
              },{
                "jmsId": 1,
                "userId": "br",
                "jmsFileName": "abc.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "fail",
                "noOfTopics": 1,
                "noOfQueues": 1,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Request not accepted"
              },{
                "jmsId": 2,
                "userId": "br",
                "jmsFileName": "xyz.yaml",
                "requestDate": "2024-08-06T06:30:30.401741",
                "status": "ok",
                "noOfTopics": 13,
                "noOfQueues": 13,
                "lastModifiedDate": "2024-08-06T06:30:30.401741",
                "remarks": "Succesfully processed"
              }],
              "totalRecords": 2365
        }
    },
    PROEJCT_SEARCH_API: {
        status: 200,
        data: {
            "userId": "sasidaren",
            "transactions": [
                {
                    "requestDate": "2024-08-21T15:32:18.73711",
                    "projectName": "prj10025_sasidaren_preload 2.zip",
                    "fileName": "preload.zip",
                    "status": "in-prog",
                    "projectId": "prj10025",
                    "xslStatus": "NA",
                    "schemaStatus": "fail",
                    "jmsStatus": "ok",
                    "remarks": "",
                    "xslAccuracy": 0,
                    "schemaAccuracy": 0,
                    "jmsAccuracy":10
                },
                {
                    "requestDate": "2024-08-21T15:31:28.29412",
                    "projectName": "prj10024_sasidaren_preload (1).zip",
                    "fileName": "preload (1).zip",
                    "status": "ok",
                    "projectId": "prj10024",
                    "xslStatus": "in-prog",
                    "schemaStatus": "fail",
                    "jmsStatus": "in-prog",
                    "remarks": "",
                    "xslAccuracy": 80,
                    "schemaAccuracy": 10,
                    "jmsAccuracy":20
                },
                {
                    "requestDate": "2024-08-21T15:19:46.481073",
                    "projectName": "prj10023_sasidaren_preload.zip",
                    "fileName": "preload (1).zip",
                    "status": "new",
                    "projectId": "prj10023",
                    "xslStatus": "ok",
                    "schemaStatus": "fail",
                    "jmsStatus": "ok",
                    "remarks": "",
                    "xslAccuracy": 80,
                    "schemaAccuracy": 10,
                    "jmsAccuracy":20
                }
            ],
            "totalRecords": 300
        }
    },
};