// composables/useCounter.js
import {  computed } from 'vue';
import { getSessionItem } from "../store/session.js";
export  function useType(a) {
  
  const getType = computed(() => getSessionItem("type"));

  return {
    getType
  };
}
