<template>
    <div v-if="validateShowing" class="hoverableSidebarMenu">
    <div class="floating-sidebar-menu" data-bs-toggle="offcanvas" data-bs-target="#floatingCanvas" aria-controls="staticBackdrop">
        <span class="label me-3 ">{{ floatingViewConfigs.title }}</span>
        <div class="floating-icon">
            <img class=" " src="../assets/icons/preview.svg" alt=" " />
            <div class="counter-badge">{{ changes['originalChanges'].length }}</div>
        </div>
    </div>
</div>

<div v-if="validateShowing" class="offcanvas offcanvas-bottom" data-bs-backdrop="true" tabindex="-1" id="floatingCanvas" aria-labelledby="staticBackdropLabel">
    <!-- <div class="offcanvas-header">
        <h3 class="offcanvas-title" id="staticBackdropLabel">Updates</h3>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div> -->
    <div class="offcanvas-body">
        <!--Editable Grid Changes If any starts-->
        <div v-if="validateShowing" class="gloss-view-content">
            <div class="code-editor">
                <h4>Updates</h4>
                <table class="table table-hover table-borderless track-changes-table">
                    <thead>
                        <tr>
                            <th v-for="(value, key) in changes['refinedChanges'][0]" :key="key">{{ key }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="change in changes['refinedChanges']" :key="change[0]">
                            <td v-for="(value, key) in change" :key="key">{{ value }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="separator"></div>
                <div class="toolbar">
                    <button @click="discardEditableGridChanges" class="discard-button">Discard</button>
                    <div class="submit-wrapper">
                        <button @click="submitEditableGridChanges" class="submit-button">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <!--Editable Grid Changes If any ends-->
    </div>
</div>
</template>

<script>
import {
    type,
    pageViews,
    EDITABLE_GRID_CHANGES_COLUMNS,
} from "../utils/util";
import * as bootstrap from 'bootstrap';
export default {
    name: pageViews.FLOATING_COMPONENT,
    props: ["changes", "floatingViewConfigs"],
    emits: ["discardEditableGridChanges","submitEditableGridChanges"],
    components: {

    },
    data() {
        return {
            
        }
    },
    computed : {
        validateShowing() {
            return ( this.changes && this.changes['refinedChanges'] && this.changes['refinedChanges'].length > 0);
        },
    },
    methods: {
        discardEditableGridChanges(){
            console.debug("About to emit an event to discard changes");
            this.$emit('discardEditableGridChanges');
            this.hideCanvas();
        },
        submitEditableGridChanges(){
            console.debug("About to emit an event to submit changes");
            this.$emit('submitEditableGridChanges');
            this.hideCanvas();
        },
        hideCanvas() {
            const offcanvasElement = document.getElementById('floatingCanvas');
            const bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
            if (bsOffcanvas) {
                bsOffcanvas.hide();
            }
        },
    },
    mounted() {
        
    },
    unmounted() {
        
    },
}
</script>

<style scoped>
.floating-icon {
    background-color: #1D2856;
    position: relative;
    left: 0px;
    border-radius: 10px;
    padding: 0.4vw;
}

.floating-icon:hover {}

.floating-sidebar-menu .floating-icon {
    left: 7%;
}

.floating-sidebar-menu {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
    z-index: 1;
    top: 7.9%;
    position: fixed;
    right: 0;
    /* transform: translateY(-50%); */
    width: 2vw;
    color: #f0ece5;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: width 0.5s;
    border-radius: 10px;
    /* border: 5px solid red; */

}

.floating-sidebar-menu img {
    height: 3vh;
    /* margin-left: 3%; */
}

.floating-sidebar-menu:hover {
    /* background-color: #F5F5DC; */
    background-color: #F5F5DC;
    width: 10.5vw;
    /* width: fit-content; */
}

.floating-sidebar-menu:hover .label {
    display: block;
}

.label {
    color: black;
    display: none;
    white-space: nowrap;
}

.gloss-view-content{
  text-align: center;
  display: grid;
}

.code-editor {
  width: 100%; 
  margin: 0 auto; 
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  font-family: monospace;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.code-content {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  max-width: fit-content;
  background-color: #dbd8d6;
  color: black;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
}

.toolbar {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
}
.separator {
  height: 2px;
  background-color: #ccc;
  margin: 16px 0;
}

.submit-wrapper {
  margin-left: auto;
}

.submit-button {
  padding: 10px 16px;
  background-color: #da6a26;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #f16915;
}

.discard-button {
  padding: 10px 16px;
  background-color: #dc3545; /* Bootstrap danger color */
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.discard-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

.track-changes-table th {
  background-color: lightgrey;
  color: #000;
}

.offcanvas {
    width: 50%;
    left: 50% !important;
    transform: translateX(-50%) !important;
    border-radius: 15px;
    overflow: auto;
    height: fit-content !important;
}

.counter-badge {
  position: absolute;
  top: -10px;
  right: 25px;
  background-color: #da6a26;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  min-width: 20px;
  text-align: center;
}

</style>
