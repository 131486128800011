<template>
  <!-- FileCheck Modal Start -->
  <div class="modal fade" id="fileCheckModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{ popup.title }}
          </h1>
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="executePrimaryAction()"
          ></button> -->
        </div>
        <div class="modal-body">{{ popup.body }}</div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="handleSecondaryAction()">
            {{ popup.secondaryActionLabel }}
          </button>
          <button type="button" class="btn btn-danger" @click="handlePrimaryAction()" data-bs-dismiss="modal">
            {{ popup.actionLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- FileCheck Modal End  -->
  <div v-cloak @drop.prevent="addFile($event, source='dropped')" @dragover.prevent @dragenter="onDragEnter" @dragleave="onDragLeave" class="file-upload" id="file-upload-id" role="dialog">
    <!-- <div class="file-upload-sub" id="file-upload-sub"> -->
    <h4>Drag and drop files here or <br />Select Files</h4>

    <!-- File Input Button -->
    <input type="file" multiple @change="handleFiles($event, source='selected')" ref="fileInput" class="file-input"
      :accept="uploadConfigs.accept" />

    <button :disabled="fileSelectionDisabled" @click="triggerFileInput">Select Files</button>

    <p v-if="files.length > 0">{{ files.length }} file(s) selected</p>
    <ul :class="{ 'scroll-class': files.length > 0 }">
      <li v-for="file in files" :key="file.name">
        {{ file.name }} ({{ formatSize(file.size) }} kb)
        <button @click="removeFile(file)" title="Remove" class="btn">X</button>
      </li>
    </ul>
    <div class="widget_text widget-wrap">
      <div class="textwidget custom-html-widget">
        <button :disabled="uploadDisabled" @click="process" class="btn1">
          {{ uploadConfigs.actionLabel }}
        </button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import axios from "axios";
import AWS from 'aws-sdk';
import { Modal } from "bootstrap";
import { toast } from "vue3-toastify";
import { action,type, user } from "../utils/util.js";
// import Store from "@/vuex/store";
import  {getCookie} from "../store/cookie.js";
import {getSessionItem, setSessionItem} from "../store/session.js";
import { makeOpearationRequest,makeXMLTransformRequest } from "@/services/apiServices";
import { useUploadStore } from "../store/fileUpload.js";

export default {
  name: "SingleFileUploadTemplate",
  computed: {
    requestId() {
      // return Store.getters.getRequestId;
      return getSessionItem("requestId");
    },
    // uploadDisabled() {
      
    //   return this.files.length === 0;
    // },
  },
  data() {
    return {
      files: [],
      userId: getCookie(user.id),
      uploadDisabled: true,
      fileSelectionDisabled: false,
      projectId: "",
      updatedFileObj: {},

      // Data properties for modal content
      popup: {
        // title: "delete",
        // body: "are you are ",
        // actionLabel: "delete",
        // secondaryActionLabel: "Cancel",
        // data: "actions",
      },
      num: 1,

      availableFiles: [],
      currentFileName: 0,
      selectedFiles: [],
      succeededFiles : [],
      failedFiles : []
    };
  },
  props: {
    uploadConfigs: {
      type: Object,
      required: true,
    },
  },

  mounted() {},

  methods: {
    showSuccess(message) {
      toast.success(message, {
        autoClose: 3000,
      });
    },
    showError(message) {
      toast.error(message, {
        autoClose: 5000,
      });
    },
    executePrimaryAction() {},

    openModal() {
      const modal = document.getElementById("fileCheckModal");
      document.body.appendChild(modal);

      var myModal = new Modal(modal, {
        keyboard: false,
      });
      myModal.show();
    },
    closeModal() {
      const modal = document.getElementById("fileCheckModal");
      document.body.appendChild(modal);

      var myModal = new Modal(modal, {
        keyboard: false,
      });
      myModal.hide();
    },

    async addFile(event, source) {
      let droppedFiles = Array.from(event.dataTransfer.files);
      const fileType = event.dataTransfer.items[0].type;
      if(droppedFiles.length == 1 && this.files.length == 0){
        if(fileType == 'application/zip') {
          /* this.uploadDisabled = false;
          this.fileSelectionDisabled = true;
          this.files = this.files.concat(droppedFiles);   */
          await this.handleFiles(event, source);
        }else{
          toast.error('Only files with extention .zip is supported')
        }
      }else{
        toast.error('Please upload only one file');
      }
      if (!droppedFiles) 
        //notify that atlast oe files needs to be selected
        return;

      //this.files = this.files.concat(droppedFiles);
    },
    onDragEnter(event) {
      event.currentTarget.classList.add('dragover');
    },
    /* onDragLeave(event) {
      this.resetDragOverState();
    },
    resetDragOverState() {
      const dropZone = document.querySelector('.drop-zone');
      console.log("Drag Leave");
      //dropZone.classList.remove('dragover');
    }, */
    removeFile(file) {
      this.uploadDisabled = true;
      this.fileSelectionDisabled = false;
      this.files = this.files.filter((f) => f !== file);
    },

    formatSize(size) {
      return (size / 1024).toFixed(2);
    },
    handlePrimaryAction() {
      this.uploadDisabled = false;
      this.fileSelectionDisabled = true;
      // if (data.action === action.REPLACE) {
      console.log("FileName to be updated", this.currentFileName);

      this.appendUnAvailFiles([this.currentFileName]);

      /* this.files = this.files.concat(
        this.selectedFiles.find((file) => file.name == this.currentFileName)
      );
      console.log("selected file is handleprimary", this.selectedFiles);
      console.log("file is handleprimary", this.files); */
      // }
      this.setConfirmModalData();
    },
    handleSecondaryAction() {
      this.uploadDisabled = true;
      this.fileSelectionDisabled = false;
      this.removeFile(
        this.selectedFiles.find(
          (file) => file.fileName === this.currentFileName
        )
      );
      console.log("FileName to be Removed:", this.currentFileName);
      this.setConfirmModalData();
    },
    setConfirmModalData() {
      if (this.availableFiles.length > 0) {
        this.currentFileName = this.availableFiles[0]; //supports only one zip file upload at the moment
        console.log("availfiles", this.availableFiles);
        console.log("current file name", this.currentFileName);

        var fileNameMsg =
          this.currentFileName +  
          " " +
          "already exist!. \n Would you like to replace?";

        let data = {
          action: action.REPLACE,
        };

        this.setConfirmModalValues(
          "File Check",
          fileNameMsg,
          "Replace",
          "Cancel",
          data
        );
        this.openModal();
        this.availableFiles = this.availableFiles.filter(
          (file) => file !== this.currentFileName
        );
      }
    },
    appendUnAvailFiles(files) {
      // for(let i=0;i<files.length;i++){
      const unAvailFiles = this.selectedFiles.filter((selectedFile) =>
        files.some((file) => file === selectedFile.name)
      );
      //Add isNew attribute to each filtered file
      unAvailFiles.forEach(file => {file.isNew = true; });
      // Concatenate the filtered files to files
      this.files = this.files.concat(unAvailFiles);
      
    },
    async handleFiles(event, source) {
      //this.uploadDisabled = false;
      this.selectedFiles= source == "selected"? Array.from(event.target.files):Array.from(event.dataTransfer.items).map(item => {return item.getAsFile()} );
      //this.selectedFiles = Array.from(event.target.files);
      console.log("selected Files in handle", this.selectedFiles);

      if (this.selectedFiles.length >= 0) {
        console.log("Selected Files:", this.selectedFiles);
        let fileName = this.selectedFiles[0].name ; //will support selecting only one file at the moment

        let payload = {
          userId: this.userId,
          fileName: fileName
        };
        
        console.log("File check req body", payload);
        let response = null;
        try {
          //response = await axios.post(process.env.VUE_APP_BASE_XSL_URL+"/checkfile", fileCheckbody);
          response = await makeOpearationRequest(this.$acclConfigs, "/checkfile", payload);

          //New File response (404)
          /* response = {
            status: 404,
            data: {
              "message": "Record not found"
            }
          } */
          //existing file response
          /* response = {
            status: 200,
            data: {
                "projectId": "prj10002",
                "projectName": "prj10002_Rafik_StoreVehicleServiceV1_3 2.zip",
                "isDeleted": false
            }
          } */
          
          console.log("Response :", response);

          if(response.status == 404){   // If the file is new
            this.appendUnAvailFiles([fileName]);
            this.setConfirmModalData();
            this.fileSelectionDisabled = true;
          }else if (response.status == 200) {   // If the file is already exists 
            this.availableFiles = [response.data.fileName];
            console.log("Avail Files length", this.availableFiles.length);
            this.setConfirmModalData();
            this.fileSelectionDisabled = true;
          }
        } catch (error) {
          console.log("Error:", error);
          response = error.response;
          if (error.response.status == 404) {
            console.log(response);
            this.appendUnAvailFiles([fileName]);
            this.setConfirmModalData();
            this.fileSelectionDisabled = true;
          }
        } finally {
          console.log(response);
        }
      }
      event.target.value = [];
      this.uploadDisabled = false;
    },

    setConfirmModalValues(
      title,
      body,
      actionLabel,
      secondaryActionLabel,
      data
    ) {
      const confirmModalData = {
        title,
        body,
        actionLabel,
        secondaryActionLabel,
        data,
      };
      this.popup = { ...confirmModalData };
      
    },
    

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    async updateProjFileNameIfRequired(rawFile) {
      if (rawFile.isNew) {
        try {
          const response = await makeOpearationRequest(this.$acclConfigs, "/getprojectid");
          if(response.status == 200) {
            this.projectId = response.data.projectId;
            setSessionItem("projectId", this.projectId);
            const updatedFileName = this.projectId+"_"+this.userId+"_"+rawFile.name;
            const newFile = new File([rawFile], updatedFileName, {type: rawFile.type});
            this.updatedFileObj = newFile;
          }
        }catch(error){
          console.log("Error:", error);
        }
      }
      return;
    },

    async uploadFileToS3(inputFile) {
      await this.updateProjFileNameIfRequired(inputFile);
      try {
        const s3 = new AWS.S3({
          accessKeyId: this.$acclConfigs['file-upload-s3-bucket-accessKey'],
          secretAccessKey: this.$acclConfigs['file-upload-s3-bucket-accessSecret'],
          region: this.$acclConfigs['file-upload-s3-bucket-region']
        });

        const params = {
          Bucket: this.$acclConfigs['file-upload-s3-bucket-name'],
          Key: this.updatedFileObj.name,
          Body: this.updatedFileObj,
          ContentType: this.updatedFileObj.type,
        };

        const data = await s3.upload(params).promise();
        console.debug("upload s3 response", data);
      }catch(error) {
        console.error("Error while uploading file to S3 bucket", error);
      }

    },
    async process() {
      this.uploadDisabled = false;
      this.fileSelectionDisabled = true;
      
      // let endpoint =
      //   this.uploadConfigs.type == type.XML
      //     ? "/transform"
      //     : "/convert";
      
      const id = toast.loading("File is being uploaded. Please wait a moment.")
      console.log("Id is:", id)



      const uploadFile = async (file) => {
        await this.uploadFileToS3(file);

        try {
          /* let formData = new FormData();
          formData.append("file", file);
          formData.append("userId", this.userId); */

          let payload = {
            userId: this.userId,
            projectId: this.projectId,
            projectName: this.updatedFileObj.name
          };
          

          // Await the axios post request
          //const response = await axios.post(endpoint, formData);
          const response = await makeOpearationRequest(this.$acclConfigs, '/migrate', payload);
          console.log("Response :", response);
          // Check the response status
          if (response.status === 202) {
            const uploadStore = useUploadStore();
            var successMessage = {
              fileName : file.name,
              message: response.data.message
            }
            this.succeededFiles.push(successMessage)
            this.removeFile(file);
            uploadStore.setUploadComplete(true);
            return successMessage // Resolve with the message
          }
        } catch (error) {
          var failureMessage = {
              fileName : file.name,
              message: error
            }
            this.failedFiles.push(failureMessage)
          return failureMessage; // Handle errors properly
        }
      };
      const uploadPromises = Array.from(this.files).map((file) =>
        uploadFile(file)
      );

      console.log("upload Promis ", uploadPromises);
      Promise.all(uploadPromises)
        .then(() => {
          // console.log("All files uploaded:", results);
          console.log("Succeed Files ", this.succeededFiles.length)
          console.log("Failed Files ", this.failedFiles.length)
          if (this.succeededFiles.length != 0) {
            // this.showSuccess(this.succeededFiles.length+" File(s) Accepted")
            setTimeout(() => {
              toast.update(id, {
                render: this.succeededFiles.length + " File(s) - '"+this.succeededFiles[0].fileName +"' accepted",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: 'success',
                isLoading: false,
              })
              this.succeededFiles = [];
            }
            )
          }
          if (this.failedFiles.length != 0) {
            //this.showError(this.failedFiles.length+" File(s) Failed");
            setTimeout(() => {
              toast.update(id, {
                render: this.failedFiles.length + " File(s) - '"+this.failedFiles[0].fileName +"' failed",
                autoClose: true,
                closeOnClick: true,
                closeButton: true,
                type: 'error',
                isLoading: false,
              })
              this.failedFiles = [];
            })

          }
        })
        .catch((error) => {
          console.log("Error in processing the migration of files:", error);
        });
     
    },
  
    report() {
      this.$emit("report");
    },
  },
};
</script>

<style>
.btn1:disabled{
  cursor:not-allowed;
}
.file-upload {
  width: 100%;
  height: 58vh;
  border: 2px dashed #333;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  float: left;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.file-upload-sub {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 8vw;
}

.file-upload:hover {
  border-color: #da6a26;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload button {
  filter: drop-shadow(0 15px 25px rgba(222, 98, 7, 0.15));
  display: inline-block;
  border: none;
  background: #da6a26;
  color: white;
  padding: 15px 40px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  justify-content: center;
}

.file-upload button:disabled {
  background-color: #ccc;
  /* Change this to your preferred disabled color */
  cursor: not-allowed;
}

.file-upload button:hover {
  background-color: #333;
}

.file-upload ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.file-upload li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.file-upload li:hover {
  background-color: #f1f1f1;
}

.file-upload li button[title="Remove"] {
  background-color: #da6a26;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.file-upload li button[title="Remove"]:hover {
  background-color: #da6a26;
}

.file-upload p {
  color: #030a07;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.scroll-class {
  /* height: min-content;
  overflow: scroll; */
  max-height: 150px;
  /* Set your desired max-height */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0;
  margin: 10px 0;
  list-style-type: none;
}

.scroll-class li {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

h4 {
  font-size: 1.2em;
  font-weight: normal;
  color: #666;
  text-align: center;
  margin-bottom: 1em;
}
</style>