
export function setSessionItem(key, value) {
    
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  // Function to get a value from session storage
  export function getSessionItem(key) {
    // Retrieve the item and parse it as JSON
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }
  
  // Function to remove an item from session storage
  export function removeSessionItem(key) {
    sessionStorage.removeItem(key);
  }

  export function displaySessionStorageItems() {
    // Get the number of items in sessionStorage
    const length = sessionStorage.length;
  
    // Iterate over the keys
    for (let i = 0; i < length; i++) {
      // Get the key at the current index
      const key = sessionStorage.key(i);
  
      // Get the item corresponding to the key
      const value = sessionStorage.getItem(key);
  
      // Display the key and value
      console.log(`Key: ${key}, Value: ${value}`);
    }
  }