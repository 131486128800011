<template>
<div class="container-fluid mb-1">
    <div class="row">
        <div class="col-1">
            <button class="btn back-button" @click="this.$router.back()">
                <span><i class="fa fa-arrow-left fa-3x"></i></span>
            </button>
        </div>
        <div class="col d-flex justify-content-center align-items-center">
            <p class="h4">Trash</p>
        </div>
        <div class="col-1"></div>
    </div>

</div>
<ReportTemplate v-if="isTableColumnsReady" :table-props="tableColumns" />
</template>

<script>
import "vue3-toastify/dist/index.css";
import ReportTemplate from "../components/ReportTemplate.vue";
import {
    getSessionItem
} from "@/store/session";

import {
    type
} from "../utils/util";
export default {
    name: "TrashView",
    watch: {
        userId: function () {
            return getSessionItem("setUserId");
        },
        requestId: function () {
            return getSessionItem("requestId");
        },
        componentType(tableType) {
            if (tableType == type.XSL) {
                this.tableColumns = {
                    type: type.XSL,
                    filters: [{
                        isDeleted: true
                    }],
                    configs: [{
                            name: "remarks",
                            label: "Remarks",
                            order: 9,
                            sort: true,
                        },
                        {
                            name: "lastModifiedDate",
                            label: "Last Modified Date",
                            order: 8,
                            sort: true,
                            type: "date",
                            default: true,
                        },

                        {
                            name: "status",
                            label: "Status",
                            order: 4,
                            sort: true,
                            default: true,
                        },
                        {
                            name: "xslFileName",
                            label: "Filename",
                            order: 3,
                            sort: true,
                            default: true,
                            textAlign: "left",
                        },
                        {
                            name: "requestDate",
                            label: "Request Date",
                            order: 1,
                            sort: true,
                            default: true,
                            type: "date",
                        },
                        {
                            name: "requestId",
                            label: "Request ID",
                            order: 2,
                            sort: true,
                            default: true,
                        },
                    ],
                    actions: {
                        restore: {
                            label: 'Restore'
                        }
                    },
                }
            } else if (tableType == type.PROJECT) {
                this.tableColumns = {
                    type: type.PROJECT,
                    defaultSortOrder: {name: "desc", label: "Descending"},
                    defaultSortColumn: {name: "requestDate", label: "Request Date"},
                    filters: [{
                        isDeleted: true
                    }],
                    configs: [{
                        name: "remarks",
                        label: "Remarks",
                        order: 12,
                        sort: false,
                        textAlign: "left",
                    },
                    {
                        name: "jmsAccuracy",
                        label: "JMS Conversion Accuracy",
                        order: 11,
                        suffix: "%",
                        search: false,
                    },
                    {
                        name: "xslAccuracy",
                        label: "XSL Conversion Accuracy",
                        order: 10,
                        suffix: "%",
                        search: false,
                    },
                    {
                        name: "schemaAccuracy",
                        label: "Schema Conversion Accuracy",
                        order: 9,
                        suffix: "%",
                        search: false,
                    },
                    {
                        name: "lastModifiedDate",
                        label: "Last Modified Date",
                        order: 8,
                        sort: true,
                        type: "date",
                    },
                    {
                        name: "jmsStatus",
                        label: "Kafka Configuration Status",
                        order: 5,
                        sort: true,
                        default: true,
                    },
                    {
                        name: "xslStatus",
                        label: "XSLT Conversion status",
                        order: 7,
                        sort: true,
                        default: true,
                    },
                    {
                        name: "schemaStatus",
                        label: "Schema Conversion Status",
                        order: 6,
                        sort: true,
                        default: true,
                    },
                    {
                        name: "status",
                        label: "Status",
                        order: 4,
                        sort: true,
                        default: true,
                    },
                    {
                        name: "fileName",
                        label: "File Name",
                        order: 3,
                        sort: true,
                        default: true,
                        checked: true,
                        textAlign: "left",
                    },
                    {
                        name: "requestDate",
                        label: "Request Date",
                        order: 1,
                        sort: true,
                        default: true,
                        type: "date",
                    },
                    {
                        name: "projectId",
                        label: "Project ID",
                        order: 2,
                        sort: true,
                    },
                    ],
                    actions: {
                        restore: {
                            label: 'Restore'
                        }
                    },
                }
            } else if (tableType == type.SCHEMA) {
                this.tableColumns = {
                    type: type.SCHEMA,
                    filters: [{
                        isDeleted: true
                    }],
                    configs: [{
                            name: "remarks",
                            label: "Remarks",
                            order: 7,
                            sort: false,
                        },
                        {
                            name: "lastModifiedDate",
                            label: "Last Modified Date",
                            order: 6,
                            sort: true,
                            type: "date"
                        },
                        {
                            name: "status",
                            label: "Status",
                            order: 5,
                            sort: true,
                            default: true,
                        },
                        {
                            name: "xsdFileName",
                            label: "File Name",
                            order: 4,
                            sort: true,
                            default: true,
                            checked: true,
                            textAlign: "left",
                        },
                        {
                            name: "requestDate",
                            label: "Request Date",
                            order: 1,
                            sort: true,
                            default: true,
                            type: "date",
                        },
                        {
                            name: "xsdId",
                            label: "Schema ID",
                            order: 3,
                            sort: true,
                        },
                        {
                            name: "projectId",
                            label: "Project ID",
                            order: 2,
                            sort: true,
                        },
                    ],
                    actions: {
                        restore: {
                            label: 'Restore'
                        }
                    },
                }
            }
        }
    },
    data() {
        return {
            tableColumns: {},
            componentType: "",
            /*xslColumns: {
              type: type.XSL,
              filters: [{ isDeleted: true }],
              configs: [
                {
                  name: "remarks",
                  label: "Remarks",
                  order: 9,
                  sort: true,
                },
                {
                  name: "lastModifiedDate",
                  label: "Last Modified Date",
                  order: 8,
                  sort: true,
                  type: "date",
                  default: true,
                },

                {
                  name: "status",
                  label: "Status",
                  order: 4,
                  sort: true,
                  default: true,
                },
                {
                  name: "xslFileName",
                  label: "Filename",
                  order: 3,
                  sort: true,
                  default: true,
                },
                {
                  name: "requestDate",
                  label: "Request Date",
                  order: 2,
                  sort: true,
                  default: true,
                  type: "date",
                },
                {
                  name: "requestId",
                  label: "Request ID",
                  order: 1,
                  sort: true,
                  default: true,
                },
              ],
              actions: {
                restore: true,
              },
            },*/
        };
    },
    components: {
        ReportTemplate,
    },
    mounted() {
        this.componentType = getSessionItem("componentType");
    },
    computed: {
        isTableColumnsReady() {
            return Object.keys(this.tableColumns).length > 0;
        }
    }
};
</script>

<style>
.back-button {
    border-radius: 50% !important;
    background-color: rgba(255, 255, 255, 0.2);
    color: black;
    border: none !important;
    /* padding:  30px !important; */
    -webkit-transition: background-color 1s, color 1s, -webkit-transform 0.5s;
    transition: background-color 1s ease, transform 0.5s ease, text-shadow 0.1s ease;
    box-shadow: 5px 0px 18px 0px rgba(105, 105, 105, 0.8);
}

.back-button:hover {
    -webkit-transform: translateX(-5px);
    text-shadow: 1px 1px 1px #000, 0 0 1px #000, 0 0 1px #000;
}

.fa-3x {
    font-size: 1.5rem;
}
</style>
