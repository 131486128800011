<template>
  <div>
    <!-- Your component template goes here -->
  </div>
</template>
<script>
import { setCookie } from "../store/cookie.js";
import { useAuthStore } from "../store/auth.js";
import { pageViews, user, app_mode } from "../utils/util.js";
import { jwtDecode } from "jwt-decode";
import 'core-js/modules/es.array.push';
export default {
  name: pageViews.CALLBACKVIEW,
  methods: {
    callback() {
      const params = new URLSearchParams(window.location.search);
      console.log("Params ", params);
      let message = ""
      if (params.get("data")) {
        const encodedjsonData = params.get("data");
        //const jsonData = JSON.parse(atob(encodedjsonData));
        const jsonData = jwtDecode(encodedjsonData);
        console.log("Json Data ", jsonData);
        if (jsonData.status && jsonData.status === "success" && jsonData.id) {
          this.onLoginSuccess(encodedjsonData);
          setCookie(user.id, jsonData.id, 7);
          setCookie(user.name, jsonData.name ? jsonData.name : jsonData.id, 7);
          setCookie(user.source, jsonData.authSource, 7);
          setCookie(user.role, jsonData.role, 7);
          console.log('The app is running in: ', app_mode)
          'independent' === app_mode ? this.redirectView(pageViews.INDEPENDENT_ACC_VIEW) : this.redirectView(pageViews.ALLINONE_ACC_VIEW)
        } else if (jsonData.status && jsonData.message) {
          message = jsonData.message
          this.redirectView(pageViews.LOGINVIEW, message)
        }
      } else {
        this.redirectView(pageViews.LOGINVIEW, message)
      }
    },
    redirectView(view, message) {
      console.log(message)
      this.$router.push({
        name: view,
        query: {
          error: message
        }
      });
    },
  },
  mounted() {
    this.callback();
  },
  setup() {
    const authStore = useAuthStore();
    function onLoginSuccess(jwtToken) {
      authStore.setToken(jwtToken);
    }
    return { onLoginSuccess, };
  },
};
</script>

<style scoped></style>