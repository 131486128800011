<template>
    <HeaderView />
    <router-view />
    <FooterView />
</template>

<script>
import HeaderView from './views/HeaderView.vue';
import FooterView from './views/FooterView.vue';

export default {
  name: 'App',
  components: {
    FooterView,
    HeaderView
  }
};
</script>

<style>
body {
  margin-bottom: 0;
}
</style>
