  <template >
    <div>
      <ReportTemplate :table-props="dashboardColumns" :key="$route.fullPath" :project-param-id="projectParamId"/>
      <CanvasView :upload-configs="uploadConfigs" />
    </div>
  </template>
  
  
  <script>
  // import UploadTemplate from './UploadTemplate.vue'
  import "vue3-toastify/dist/index.css";
  import ReportTemplate from "../components/ReportTemplate.vue";
  import CanvasView from "../components/CanvasView.vue";
  import Store from "../vuex/store.js";
  import { type,pageViews, app_mode } from "../utils/util";
  export default {
    name: pageViews.ALLINONE_ACC_VIEW,
    methods: {
      setValuesToStore(type) {
        Store.commit("setType", type);
      },
      /*callback() {
        const params = new URLSearchParams(window.location.search);
        const status = params.get("status");
        console.log("Status ", status);
        if (status === "success") {
          this.status = true;
          // Handle successful login
          const userId = params.get("user_id");
          console.log("User Id ", userId);
          // Store userId or fetch additional data
        } else if (status === "fail") {
          this.status = false;
          // Handle error
          const message = params.get("message");
          this.$router.push({
            name: "LoginView",
          });
          alert( message);
        }
      },*/
    },
    components: {
        ReportTemplate,
        CanvasView,
      // UploadTemplate
    },
    mounted() {
      //this.callback()
      this.setValuesToStore(type.PROJECT);
    },
    unmounted() {
      console.log("unmounted");
    },
  
    activated() {
      console.log("activated");
    },
    deactivated() {
      console.log("deactivated");
    },
  
    data() {
      return {
        uploadConfigs: {
          action: "bulk_process",
          actionLabel: "Migrate",
          title: "Migrate",
          type: type.PROJECT,
          accept: ".zip",
        },
        dashboardColumns: {
          type: type.PROJECT,
          isMultiSelectionAllowed: true,
          defaultSortOrder: {name: "desc", label: "Descending"},
          defaultSortColumn: {name: "requestDate", label: "Request Date"},
          configs: [
            {
              name: "remarks",
              label: "Remarks",
              order: 12,
              sort: false,
              textAlign: "left",
            },
            {
              name: "jmsAccuracy",
              label: "JMS Conversion Accuracy",
              order: 11,
              suffix: "%",
              search: false,
            },
            {
              name: "xslAccuracy",
              label: "XSL Conversion Accuracy",
              order: 10,
              suffix: "%",
              search: false,
            },
            {
              name: "schemaAccuracy",
              label: "Schema Conversion Accuracy",
              order: 9,
              suffix: "%",
              search: false,
            },
            {
              name: "lastModifiedDate",
              label: "Last Modified Date",
              order: 8,
              sort: true,
              type: "date",
            },
            {
              name: "jmsStatus",
              label: "Kafka Configuration Status",
              order: 5,
              sort: true,
              default: true,
              actionable: true,
              routeTo: pageViews.KAFKACONFIGGENERATORVIEW
            },
            {
              name: "xslStatus",
              label: "XSLT Conversion status",
              order: 7,
              sort: true,
              default: true,
              actionable: true,
              routeTo: pageViews.CONVERTERVIEW
            },
            {
              name: "schemaStatus",
              label: "Schema Conversion Status",
              order: 6,
              sort: true,
              default: true,
              actionable: true,
              routeTo: pageViews.SCHEMACONVERTERVIEW
            },
            {
              name: "status",
              label: "Status",
              order: 4,
              sort: true,
              default: true,
            },
            {
              name: "fileName",
              label: "File Name",
              order: 3,
              sort: true,
              default: true,
              checked: true,
              textAlign: "left",
            },
            {
              name: "requestDate",
              label: "Request Date",
              order: 1,
              sort: true,
              default: true,
              type: "date",
            },
            {
              name: "projectId",
              label: "Project ID",
              order: 2,
              sort: true,
            },
          ],
          actions: {
            download: {
              label : 'Download'
            },
            delete: {
              label : 'Delete'
            },
          },
        }
      };
    },
    props: ["projectParamId"]
  };
  </script>
  